<template>
  <div>
    <validation-observer
      v-if="ready"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form @submit.prevent="handleSubmit(submitHandler)">
        <div class="row">
          <div class="col-md-8">
            <h6>Основная информация</h6>
            <!-- Last Name -->
            <validation-provider
              #default="ctx"
              rules="required|alpha|min:3"
              name="Фамилия"
            >
              <b-form-group
                label="Фамилия"
                label-for="last_name"
                :invalid-feedback="ctx.errors[0]"
                :state="getValidationState(ctx)"
              >
                <b-form-input
                  id="last_name"
                  v-model="formData.last_name"
                  :state="getValidationState(ctx)"
                  trim
                />
              </b-form-group>
            </validation-provider>

            <!-- First Name -->
            <validation-provider
              #default="ctx"
              rules="required|alpha|min:3"
              name="Имя"
            >
              <b-form-group
                label="Имя"
                label-for="first_name"
                :invalid-feedback="ctx.errors[0]"
                :state="getValidationState(ctx)"
              >
                <b-form-input
                  id="first_name"
                  v-model="formData.first_name"
                  :state="getValidationState(ctx)"
                  trim
                />
              </b-form-group>
            </validation-provider>

            <!-- Middle Name -->
            <validation-provider
              #default="ctx"
              rules="min:3"
              name="Отчество"
            >
              <b-form-group
                label="Отчество"
                label-for="middle_name"
              >
                <b-form-input
                  id="middle_name"
                  v-model="formData.middle_name"
                  :state="getValidationState(ctx)"
                  trim
                />
                <b-form-invalid-feedback>
                  {{ ctx.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Email -->
            <validation-provider
              #default="ctx"
              rules="email"
              name="Email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="formData.email"
                  :state="getValidationState(ctx)"
                  trim
                />
                <b-form-invalid-feedback>
                  {{ ctx.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Phone -->
            <validation-provider
              #default="ctx"
              rules="min:6"
              name="Телефон"
            >
              <b-form-group
                label="Телефон"
                label-for="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="formData.phone"
                  :state="getValidationState(ctx)"
                  trim
                />
                <b-form-invalid-feedback>
                  {{ ctx.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Address -->
            <validation-provider
              #default="ctx"
              rules=""
              name="Адрес"
            >
              <b-form-group
                label="Адрес"
                label-for="address"
                :invalid-feedback="ctx.errors[0]"
                :state="getValidationState(ctx)"
              >
                <b-form-textarea
                  id="address"
                  v-model="formData.address"
                  :state="getValidationState(ctx)"
                  trim
                />
              </b-form-group>
            </validation-provider>
            <b-form-group
              label="Комментарий"
            >
              <b-form-textarea
                v-model="formData.comment"
                trim
              />
            </b-form-group>
          </div>
          <div class="col-md-4">
            <h6 class="text-md-right">
              Договор
            </h6>
            <b-form-group
              label="Серия/Номер"
              label-for="contract"
            >
              <validation-provider
                #default="ctx"
                rules="vcontract"
                name="Серия/Номер"
              >
                <driver-contract-input
                  id="contract"
                  v-model="contract"
                  :state="getValidationState(ctx)"
                />
                <b-form-invalid-feedback>
                  {{ ctx.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Yandex договор"
            >
              <b-form-input
                v-model="formData.yandex_contract"
              />
            </b-form-group>
            <hr>

            <h6 class="text-md-right">
              Паспорт
            </h6>
            <!-- Passport number -->
            <validation-provider
              #default="ctx"
              rules="required"
              name="Серия/Номер"
            >
              <b-form-group
                label="Серия/Номер"
                label-for="passport_number"
              >
                <b-form-input
                  id="passport_number"
                  v-model="formData.passport_number"
                  :state="getValidationState(ctx)"
                  trim
                />
                <b-form-invalid-feedback>
                  {{ ctx.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Passport code -->
            <validation-provider
              #default="ctx"
              rules="required"
              name="Код подразделения"
            >
              <b-form-group
                label="Код подразделения"
                label-for="passport_code"
              >
                <b-form-input
                  id="passport_code"
                  v-model="formData.passport_code"
                  :state="getValidationState(ctx)"
                  trim
                />
                <b-form-invalid-feedback>
                  {{ ctx.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <h6 class="text-md-right">
              СНИЛС
            </h6>
            <!-- Sts number -->
            <validation-provider
              #default="ctx"
              rules="required"
              name="СНИЛС"
            >
              <b-form-group
                :invalid-feedback="ctx.errors[0]"
                :state="getValidationState(ctx)"
              >
                <b-form-input
                  v-model="formData.snils_number"
                  :state="getValidationState(ctx)"
                />
              </b-form-group>
            </validation-provider>

            <h6 class="text-md-right">
              Водительское удостоверение
            </h6>

            <!-- License Number -->
            <validation-provider
              #default="ctx"
              rules="required"
              name="Серия/Номер"
            >
              <b-form-group
                label="Серия/Номер"
                label-for="license_number"
                :invalid-feedback="ctx.errors[0]"
                :state="getValidationState(ctx)"
              >
                <b-form-input
                  id="license_number"
                  v-model="formData.license_number"
                  :state="getValidationState(ctx)"
                  trim
                />
              </b-form-group>
            </validation-provider>

            <!-- License Date -->
            <validation-provider
              #default="ctx"
              rules="regex:^\d\d\d\d-\d\d-\d\d$"
              name="Дата выдачи"
            >
              <b-form-group
                label="Дата выдачи"
                label-for="license_date"
              >
                <b-form-input
                  id="license_date"
                  v-model="formData.license_date"
                  type="date"
                  :state="getValidationState(ctx)"
                  trim
                />
                <b-form-invalid-feedback>
                  {{ ctx.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <!-- carrier number -->
            <b-form-group
              label="Реестровый номер перевозчика"
            >
              <b-form-input
                v-model="formData.carrier_number"
              />
            </b-form-group>
          </div>
        </div>
        <hr>
        <div class="row">

          <!-- Payments config -->
          <!--
          <div class="col-8">
            <h6>Отправка денежных средств</h6>
            <div class="row">
              <div class="col-6">
                <validation-provider
                  #default="ctx"
                  rules="integer"
                  name="Свыше суммы"
                >
                  <b-form-group
                    label="Свыше суммы"
                    label-for="payments_over"
                  >
                    <b-form-input
                      id="payments_over"
                      v-model="formData.payments_over"
                      type="number"
                      step="1"
                      min="0"
                      :state="getValidationState(ctx)"
                    />
                    <b-form-invalid-feedback>
                      {{ ctx.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
              <div class="col-6">
                <b-form-checkbox
                  id="payments_document"
                  v-model="formData.payments_document"
                  class="mt-md-2"
                >
                  Заявление о выплате
                </b-form-checkbox>
                <b-form-checkbox
                  id="payments_send"
                  v-model="formData.payments_send"
                >
                  Отправлять деньги
                </b-form-checkbox>
                <b-form-checkbox
                  id="payments_qiwi"
                  v-model="formData.payments_qiwi"
                >
                  Выплаты киви
                </b-form-checkbox>
              </div>
            </div>
          </div>
          -->
          <!-- Car contract -->
        </div>
        <driver-car-contract-form
          :value="car_contract"
          @input="onCarContractInput"
        />
        <div class="d-flex justify-content-center mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Сохранить
          </b-button>
          <b-button
            type="button"
            variant="outline-secondary"
            @click="$emit('complete')"
          >
            Отмена
          </b-button>
        </div>
      </b-form>
    </validation-observer>
    <div
      v-else
      class="d-flex justify-content-center"
    >
      <div
        class="spinner-border m-5"
        role="status"
        style="width: 6rem; height: 6rem;"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, regex, min, alpha, email } from 'vee-validate/dist/rules'

import formValidation from '@core/comp-functions/forms/form-validation'
// eslint-disable-next-line no-unused-vars
import { vcontract } from '@core/utils/validations/validations'
import DriverContractInput from './components/DriverContractInput.vue'
import { emptyDriverContract } from './driversData'
import DriverCarContractForm from './components/DriverCarContractForm.vue'

extend('required', required)
extend('min', min)
extend('alpha', alpha)
extend('regex', regex)
extend('email', email)

export default {
  components: {
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    DriverContractInput,
    DriverCarContractForm,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    driverId: {
      type: Number,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      ready: false,
      formData: {},
      contract_numbers: {},
      car_contract: null,
    }
  },
  computed: {
    contract: {
      get() {
        return {
          serial: this.formData.contract_serial,
          number: this.formData.contract_number,
        }
      },
      set(val) {
        this.formData.contract_serial = val.serial
        this.formData.contract_number = val.number
      },
    },
  },
  watch: {
    ready(val) {
      if (val) {
        this.setDefaultNumber()
      }
    },
  },
  created() {
    this.$axios.get('drivers/contracts')
      .then(response => {
        this.contract_numbers = response.data.data
      })
      .catch()
      .finally(() => {
        this.fillFormData()
      })
  },
  methods: {
    fillFormData() {
      if (this.driverId) {
        this.$axios.get(`drivers/${this.driverId}`)
          .then(response => {
            this.formData = {
              ...emptyDriverContract,
              ...response.data.data,
            }
            this.car_contract = response.data.data.car_contract ?? []
            this.ready = true
          })
          .catch()
      } else {
        this.formData = {
          ...emptyDriverContract,
        }
        this.car_contract = []
        this.ready = true
      }
    },
    setDefaultNumber() {
      if (!this.formData.contract_number && this.formData.contract_serial) {
        // eslint-disable-next-line no-restricted-syntax,guard-for-in
        for (const i in this.contract_numbers) {
          const {
            contract_serial,
            contract_number,
          } = this.contract_numbers[i]
          // eslint-disable-next-line camelcase
          if (contract_serial === this.formData.contract_serial) {
            // eslint-disable-next-line camelcase
            this.formData.contract_number = contract_number + 1
            return
          }
        }
        this.formData.contract_number = 1
      }
    },
    submitHandler() {
      let promise = null
      const sendData = { ...this.formData, car_contract: this.car_contract }
      if (this.driverId) {
        promise = this.$axios.put(`drivers/${this.driverId}`, sendData)
      } else {
        promise = this.$axios.post('drivers', sendData)
      }
      promise.then(() => {
        this.$emit('updated', this.formData)
        this.$emit('complete')
      }).catch(error => {
        this.$bvToast.toast(error.response.data.message, {
          title: 'Ошибка',
          variant: 'danger',
          autoHideDelay: 5000,
        })
      })
    },
    onCarContractInput(value) {
      this.car_contract = value
    },
  },
}
</script>
<script setup>
const {
  // eslint-disable-next-line no-unused-vars
  refFormObserver,
  // eslint-disable-next-line no-unused-vars
  getValidationState,
} = formValidation(() => [])
</script>
