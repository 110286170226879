<template>
  <div class="row">
    <div class="col-12">
      <h5 class="text-md-left">
        Договор аренды
      </h5>
      <validation-provider
        #default="ctx"
        rules="integer"
        name="Номер"
      >
        <div class="row">
          <b-form-group
            label="Номер"
            label-for="car_number"
            class="col-4"
          >
            <b-form-input
              id="car_number"
              v-model="formData.number"
              :state="getValidationState(ctx)"
            />
            <b-form-invalid-feedback>
              {{ ctx.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </validation-provider>
    </div>
    <div class="col-6">
      <h6>Сутки</h6>
      <validation-provider
        #default="ctx"
        rules="regex:^\d\d\d\d-\d\d-\d\d$"
        name="Подписан"
      >
        <b-form-group
          label="Подписан"
          label-for="car_date_start"
          :invalid-feedback="ctx.errors[0]"
          :state="getValidationState(ctx)"
        >
          <b-form-input
            id="car_date_start"
            v-model="formData.day.date_start"
            type="date"
            :state="getValidationState(ctx)"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        #default="ctx"
        rules="regex:^\d\d\d\d-\d\d-\d\d$"
        name="Действителен до"
      >
        <b-form-group
          label="Действителен до"
          label-for="car_date_end"
          :invalid-feedback="ctx.errors[0]"
          :state="getValidationState(ctx)"
        >
          <b-form-input
            id="car_date_end"
            v-model="formData.day.date_end"
            type="date"
            :state="getValidationState(ctx)"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        #default="ctx"
        name="Залог внес, руб."
      >
        <b-form-group
          label="Залог внес, руб."
        >
          <b-form-input
            v-model="formData.day.params.deposit_made"
            type="number"
            :state="getValidationState(ctx)"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        #default="ctx"
        name="Залог До, руб."
      >
        <b-form-group
          label="Залог До, руб."
        >
          <b-form-input
            v-model="formData.day.params.deposit_up"
            type="number"
            :state="getValidationState(ctx)"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        #default="ctx"
        name="Размер аренды, руб."
      >
        <b-form-group
          label="Размер аренды, руб."
        >
          <b-form-input
            v-model="formData.day.params.amount_rent"
            type="number"
            :state="getValidationState(ctx)"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        #default="ctx"
        name="График платежей"
      >
        <b-form-group
          label="График платежей"
        >
          <b-form-input
            v-model="formData.day.params.payment_schedule"
            :state="getValidationState(ctx)"
          />
        </b-form-group>
      </validation-provider>
    </div>
    <div class="col-6">
      <h6>Смена</h6>
      <validation-provider
        #default="ctx"
        rules="regex:^\d\d\d\d-\d\d-\d\d$"
        name="Подписан"
      >
        <b-form-group
          label="Подписан"
          label-for="car_date_start"
          :invalid-feedback="ctx.errors[0]"
          :state="getValidationState(ctx)"
        >
          <b-form-input
            id="car_date_start"
            v-model="formData.change.date_start"
            type="date"
            :state="getValidationState(ctx)"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        #default="ctx"
        rules="regex:^\d\d\d\d-\d\d-\d\d$"
        name="Действителен до"
      >
        <b-form-group
          label="Действителен до"
          label-for="car_date_end"
          :invalid-feedback="ctx.errors[0]"
          :state="getValidationState(ctx)"
        >
          <b-form-input
            id="car_date_end"
            v-model="formData.change.date_end"
            type="date"
            :state="getValidationState(ctx)"
          />
        </b-form-group>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  ValidationProvider,
} from 'vee-validate'
import {
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { emptyDriverCarContract } from '../driversData'

function emptyForm() {
  return {
    number: null,
    day: {
      ...emptyDriverCarContract,
      params: {
        ...emptyDriverCarContract.params,
      },
    },
    change: {
      ...emptyDriverCarContract,
      params: null,
    },
  }
}

export default {
  components: {
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
  },
  props: {
    value: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      formData: emptyForm(),
    }
  },
  watch: {
    formData: {
      deep: true,
      handler({ day, change, number }) {
        this.$emit('input', [
          {
            ...{
              ...day,
              type: 'day',
              number,
            },
          },
          {
            ...{
              ...change,
              type: 'change',
              number,
            },
          },
        ])
      },
    },
  },
  mounted() {
    this.value.forEach(item => {
      if (item.number) {
        this.formData.number = item.number
      }
      this.formData[item.type] = item
      if (item.type === 'day') {
        this.formData[item.type].params = {
          ...emptyForm().params,
          ...item.params,
        }
      }
    })
  },
}
</script>
<script setup>
const {
  // eslint-disable-next-line no-unused-vars
  refFormObserver,
  // eslint-disable-next-line no-unused-vars
  getValidationState,
} = formValidation(() => [])
</script>
