import store from '@/store'

export const tableColumns = [
  {
    key: 'serial',
    label: '#',
  },
  {
    key: 'statuses',
    label: '',
  },
  {
    key: 'contract',
    label: 'Номер',
    sortable: true,
  },
  {
    key: 'fio',
    label: 'ФИО',
    sortable: true,
    formatter: (val, key, driver) => `${driver.last_name} ${driver.first_name} ${driver.middle_name ?? ''}`,
  },
  {
    key: 'email',
    label: 'Email',
    sortable: true,
  },
  {
    key: 'phone',
    label: 'Телефон',
    sortable: true,
  },
  {
    key: 'balance',
    label: 'Баланс',
    sortable: true,
  },
]

export const emptyDriverCarContract = {
  id: null,
  date_start: '',
  date_end: '',
  is_active: 0,
  number: '',
  params: {
    deposit_made: null,
    deposit_up: null,
    amount_rent: null,
    payment_schedule: '',
  },
  type: '',
}

export const emptyDriverContract = {
  id: null,
  first_name: '',
  middle_name: '',
  last_name: '',
  email: '',
  phone: '',
  passport_number: '',
  passport_code: '',
  contract_serial: 'АС',
  contract_number: '',
  license_number: '',
  license_date: '',
  payments_over: 100,
  payments_document: false,
  payments_send: false,
  contracts: [],
  bank_details: [],
  references: [],
  snils_number: '',
  yandex_contract: '',
  comment: '',
  carrier_number: '',
}

export const driverOperationsColumns = [
  {
    key: 'serial',
    label: '#',
  },
  {
    key: 'date',
    label: 'Дата',
    sortable: true,
  },
  {
    key: 'amount',
    label: 'Сумма',
    sortable: true,
  },
  {
    key: 'type',
    label: 'Категория',
    sortable: true,
    formatter: type => store.getters['directories/operation'].filter(item => item.code === type).pop()?.title ?? '-',
  },
  {
    key: 'out_key',
    label: 'Источник',
    sortable: true,
    formatter: key => store.getters['directories/outKey'].filter(item => item.code === key).pop()?.title ?? '-',
  },
  {
    key: 'description',
    label: 'Описание',
  },
  {
    key: 'balance',
    label: 'Баланс',
  },
  {
    key: 'actions',
    label: '',
  },
]
