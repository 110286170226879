<template>
  <b-input-group @blur="$emit('blur')">
    <b-form-input
      ref="serial-input"
      :disabled="guarded"
      class="contract-serial-field"
      :state="state"
      :value="value.serial"
      @input="updatedFields"
    />
    <b-form-input
      ref="number-input"
      :disabled="guarded"
      :state="state"
      :value="value.number"
      @input="updatedFields"
    />
    <b-input-group-append>
      <b-input-group-text @click="guarded = !guarded">
        <b-icon-lock-fill v-if="guarded" />
        <b-icon-unlock-fill v-else />
      </b-input-group-text>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import {
  BInputGroup, BFormInput, BIconLockFill, BIconUnlockFill, BInputGroupAppend, BInputGroupText,
} from 'bootstrap-vue'

export default {
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupText,
    BInputGroupAppend,
    BIconLockFill,
    BIconUnlockFill,
  },
  props: {
    state: {
      type: Boolean,
      default: null,
    },
    value: {
      type: Object,
      default() {
        return {
          serial: '',
          number: '',
        }
      },
    },
  },
  data() {
    return {
      guarded: true,
    }
  },
  methods: {
    updatedFields() {
      this.$emit('input', {
        serial: this.$refs['serial-input'].$el.value,
        number: this.$refs['number-input'].$el.value,
      })
    },
  },
}
</script>

<style scoped>
.contract-serial-field {
  background-image: none !important;
}
</style>
