<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <v-table
        ref="drivers-list"
        path="/drivers?populate=carContract"
        table-sm
        :fields="tableColumns"
        :filter="{ ...searchFilter, search }"
      >
        <template #create-button>
          <b-col
            cols="12"
            md="8"
          >
            <div
              class="d-flex justify-content-around"
            >
              <b-form-checkbox
                v-model="searchFilter.has_car_contract"
                unchecked-value="0"
                value="1"
                class="mt-1 mr-2"
              >Аренда</b-form-checkbox>
              <div
                class="btn btn-primary"
                @click="openDriverForm"
              >
                <feather-icon icon="PlusIcon" /> Добавить
              </div>
            </div>
          </b-col>
        </template>
        <template #cell(serial)="data">
          <v-table-serial-column :data="data" />
        </template>
        <template #cell(statuses)="{ item }">
          <driver-statuses :driver="item" />
        </template>
        <template #cell(contract)="{ item }">
          {{ item | contractNumber }}
        </template>
        <template #cell(fio)="{ value, item }">
          <a
            href="#"
            @click.prevent="viewDriverHandler(item.id)"
          >
            {{ value }}
          </a>
        </template>
      </v-table>
    </b-card>
    <b-modal
      id="driver-form"
      size="lg"
      title="Добавить водителя"
      hide-footer
      @hide="onDriverFormHideHandler"
    >
      <driver-contract-form
        @cancel="closeDriverForm"
        @complete="completeDriverFormHandler"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BCard, BModal, BCol, BFormCheckbox,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { tableColumns } from '@/views/drivers/driversData'
import VTable, {
  VTableSerialColumn,
} from '@/views/components/vtable'
import DriverContractForm from '@/views/drivers/DriverContractForm.vue'
import DriverStatuses from './components/DriverStatuses.vue'

export default {
  components: {
    BCard,
    BModal,
    BCol,
    BFormCheckbox,
    VTable,
    VTableSerialColumn,
    DriverContractForm,
    DriverStatuses,
  },
  data() {
    return {
      tableColumns,
      searchFilter: {
        has_car_contract: 0,
      },
      searchTimeout: null,
    }
  },
  computed: {
    search() {
      return this.$store.getters['app/search']
    },
  },
  methods: {
    refreshDriversList() {
      this.$refs['drivers-list'].refresh()
    },
    viewDriverHandler(id) {
      this.$router.push({ name: 'driver-view', params: { id } })
    },
    openDriverForm() {
      this.$bvModal.show('driver-form')
    },
    closeDriverForm() {
      this.$bvModal.hide('driver-form')
    },
    completeDriverFormHandler() {
      this.closeDriverForm()
      this.refreshDriversList()
    },
    onDriverFormHideHandler(event) {
      if (event.trigger === 'backdrop') {
        event.preventDefault()
      }
    },
  },
}
</script>
