<template>
  <div class="small">
    <div
      v-for="contract in driver.car_contract"
      :key="contract.id"
    >
      <b-badge
        :variant="contract.type === 'day' ? 'danger' : 'warning'"
      >{{ contract.type === 'day' ? 'Сутки' : 'Смена' }}</b-badge>
    </div>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BBadge,
  },
  props: {
    driver: {
      type: Object,
      required: true,
    },
  },
  computed: {
    contractDateOut() {
      const now = moment()
      return this.driver.contracts.filter(item => now.isBetween(item.date_start, item.date_end, undefined, '[]')).length === 0
    },
    bankDateOut() {
      const now = moment()
      return this.driver.bank_details.filter(item => now.isSameOrAfter(item.date_start) && (item.date_end === null || now.isSameOrBefore(item.date_end))).length === 0
    },
  },
}
</script>
